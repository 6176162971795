import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getSIProtectDetails, siProtectDetailsAsync } from '../reducers/siProtect.slice';
import { getMultiUserData, getMultiUserDetails, multiUserDataAsync, multiUserDetailsAsync } from '../reducers/multiUserDetails.slice';
import {
  Account
} from '@mediassistrepo/maven-old';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import { beneficiariesAsync, getActiveBeneficiaries, getAllBeneficiaries, getBeneficiaries, setActiveBeneficiaries, setAllBenefs } from '../reducers/beneficiaries.slice';
import { useNavigate } from 'react-router';
import { feedBackCommon, getCookieValue, logAPIStatus, postToNativeApp } from '../common/helpers';
import { getLogAPIStatusDTO } from '../common/loggerDtoGenerator';
import { RouteConstant  ,LoggerConstant } from "../common/common.constants";
import {DownloadECard} from '@mediassistrepo/homepage-component';
import { formatDateToObject, getAccessToken } from '../utils';
import { multiUserLogin, multiUserLoginAccessToken, unlinkUser } from '../api/loginAPI';
import { saveAccessToken } from '../reducers/login.slice';
import { fetchMultiUserFilterDetails, verifyMultiUserApi } from '../api/accountAPI';
import Loader from './shared/Loader';
import { downloadEcardByMember, downloadEcardFile } from '../utils/downloadEcard';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import SurveyMain from '../common/Survey/SurveyMain';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants'
import { getConfig } from '../reducers/config.slice';

require("@mediassistrepo/maven-old/dist/style.css");


const AccountComponent = (props:any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const siProtectDetails = useAppSelector(getSIProtectDetails);
  const multiUserDetails = useAppSelector(getMultiUserDetails);
  const multiUserData = useAppSelector(getMultiUserData);
  const benefResponse = useAppSelector(getBeneficiaries);
  const nativeApp = getCookieValue('source') === 'native' ? true : false;
  const accessToken = getAccessToken();
  const baseProfile = useAppSelector(getBaseProfile);
  const cachedActiveBeneficiaries  = useAppSelector(getActiveBeneficiaries);
  const [loading, setLoading] = React.useState(false);
  const cachedAllBenefs = useAppSelector(getAllBeneficiaries);
  const [isMultiUserActive, setShowMultiUserActive] = useState(false);
  const [feedBackModalShow,setFeedBackModalShow]=useState(false);
  const [bioMetricStatus,setBioMetricStatus]=useState(false);

  const [maid,setMaid]=useState<string>('');
  const corporateConfig = useAppSelector(getConfig);
  const [biometricType,setBiometricType]=useState('');
  const [showBiometricTab,setShowBiometricTab]=useState(false);


  useEffect(() => {
    if(!siProtectDetails?.siProtectDetails){
      dispatch(siProtectDetailsAsync());
    }
    if(!multiUserDetails){
      dispatch(multiUserDetailsAsync());
    }
    if(!benefResponse?.beneficiaryDetails){
      dispatch(beneficiariesAsync());
    }
    if(!multiUserData  &&  !accessToken){
      dispatch(multiUserDataAsync())
    }
  }, []);

  useEffect(() => {
    if(cachedActiveBeneficiaries){
      return;
   }
    const activeBeneficiaries = benefResponse?.beneficiaryDetails
      ?.filter((benefs) => formatDateToObject(benefs?.policyEndDate) >= new Date());
    postToNativeApp({"activeBenefCount":activeBeneficiaries?.length})
    dispatch(setActiveBeneficiaries(activeBeneficiaries));

  }, [benefResponse]);

  useEffect(() => {
    if(cachedAllBenefs){
      return;
   }
    const allBenefs = benefResponse?.beneficiaryDetails
      ?.map((benefs) =>
      ({
        ...benefs,
        isActive: benefs?.isActive && formatDateToObject(benefs?.policyEndDate) >= new Date()
      })
      ).sort((benef1, benef2) => {
        return formatDateToObject(benef2.policyEndDate).getTime() - formatDateToObject(benef1.policyEndDate).getTime()
      });
    dispatch(setAllBenefs(allBenefs));
  }, [benefResponse]);

  const handleMultipleUserProceed = async(req: any) => {
    const response = await multiUserLoginAccessToken(req);
    saveAccessToken(response.data)
    return response.data
  }

  const handleUnlink = async(request:any) => {
    const response = await unlinkUser(request)
    return response.data;
  }

  const handleMultiUserRequest = async(request: any) => {
    const response = await multiUserLogin(request);
    return response.data
  }
  useEffect(() => {
    postToNativeApp({"getBiometricStatus":true})
    if (getCookieValue('platform') == 'ios') {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    else if(getCookieValue('platform') == 'android'){
      
      document.addEventListener('message', handleMessage);
      return () => {
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  const handleMessage = (event: any) => {
    
    const message = JSON.parse(event.data);
    if (message.event === 'urlToWeb') {
      if (message.data.includes('claim-list')) {
        navigate("/claim-list")
      }
      else if (message.data.includes('policy-list')) {
        navigate("/policy-list")
      }
      else if (message.data.includes('account')) {
        navigate("/account")
      }
 
      else {
        navigate("/")
      }
    }
    else if (message.event == 'biometricStatus') {
      setShowBiometricTab(true)
      setBioMetricStatus(message?.data?.value) //this is to check biometric is enabled or not
      setBiometricType(message?.data?.type) // set type of the biometric type ex - face id , touch id
    }
    else if (message.event === 'EcardSuccess') {
      HandlefeedBack("E_CARD");
    }
  };
  
  const filterMultiUser = async(request:any) => {
    const response = await fetchMultiUserFilterDetails(request);
    return response.data;
  } 

  const verifyMultiUser = async(req:any) => {
    const response = await verifyMultiUserApi(req);
    return response.data;
  }
  const HandlefeedBack= async (entityType :string)=>{
    const show= await feedBackCommon(entityType)
    setFeedBackModalShow(show);
    setMaid(localStorage.getItem("maid") )
  }
  return (
    <>
    {(loading) && <Loader />}
    {feedBackModalShow && <div className="relative">
      <SurveyMain pageType='Account' pageTitle='E-card successfully downloaded.' Id={maid} source ={"ecard"} entity_Type={"E_CARD"} closeFeedbackModal={() => setFeedBackModalShow(!feedBackModalShow)}/>
      </div>}
    <div style={{ paddingBottom: "120px" }}>
    <HeaderWeb handleEcard={() => props.setIsEcardOpen(true)} isShowBackButton={true} oldHeader={window.innerWidth < 1024  ?true : false} name={"Profile"} />
      {props.isEcardOpen && <DownloadECard sectionName={Constants.Instance.TOP_NAVIGATION_BAR} dataLayerPush={dataLayerPush} isVisible={props.isEcardOpen} toggleBottomModal={() => {
        props.setIsEcardOpen(false)
      }} membersData={cachedActiveBeneficiaries || []}
        handleEcardDownload={async (selectedMaids:any) => {
        await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,selectedMaids );
          HandlefeedBack("E_CARD");
        }} onCloseClick={() => {
          if (getCookieValue('source') === 'native') {
            postToNativeApp({ "hideFooter": false })
            }
          props.setIsEcardOpen(false)
        }} ></DownloadECard>}
      <Account
        beneficiaryDetailsRes={cachedAllBenefs || []}
        isMultiUser={multiUserDetails?.isMultiuser}
        setShowMultiUserActive={setShowMultiUserActive}
        mulitUserDetails={multiUserDetails}
        siDetails={siProtectDetails}
        showBiometric={corporateConfig?.faceIdFingerprintActivated && multiUserDetails?.isMultiuser !== true && showBiometricTab}
        biometricStatus = {bioMetricStatus}
        biometricType={biometricType}
        handleSiProtectRedirect={() => { 
          dataLayerPush(Constants.Instance.ACCOUNT_PAGE_CTA_INTERACTION, { 
            cta_text: "Secure your transactions"
          });
          navigate("/siProtect") }}
        handleFamilyMemberDetailsRedirect={() => { 
          dataLayerPush(Constants.Instance.ACCOUNT_PAGE_CTA_INTERACTION, { 
            cta_text: "All covered members"
          });
          navigate("/family-member-details") }}
        handleBankAccountRedirect={() => { 
          dataLayerPush(Constants.Instance.ACCOUNT_PAGE_CTA_INTERACTION, { 
            cta_text: "Bank Account Details"
          });
          navigate("/bankAccount") }}
        handleResetPasswordRedirect={() => {
          dataLayerPush(Constants.Instance.ACCOUNT_PAGE_CTA_INTERACTION, { 
            cta_text: "Reset Password"
          }); 
          navigate("/reset-password") 
          logAPIStatus(getLogAPIStatusDTO(LoggerConstant.ONCLICK_PASSWORD, '/reset-password', {}, {isSuccess: true}, LoggerConstant.ONCLICK_PASSWORD));
      }}
        handleLogout={() => {
          dataLayerPush(Constants.Instance.ACCOUNT_PAGE_CTA_INTERACTION, { 
            cta_text: "Sign out"
          });
          navigate("/logout")
        }}
        handleLoginRedirect={() => {
          navigate("/")
          window.location.reload();
        }}
        handleLinkPolicyRedirect={() => navigate("/link-policy")}
        handleGuestRedirect={() => navigate("/signup")}
        handleMultipleUserProceed={handleMultipleUserProceed}
        handleUnlink={handleUnlink}
        handleMultiUserRequest={handleMultiUserRequest}
        filterMultiUser={filterMultiUser}
        verifyMultiUser={verifyMultiUser}
      />
          {(!nativeApp || props.showClaimSubmissionModal) && <FooterWeb isMultiUserActive={isMultiUserActive} handleEcard={() => props.setIsEcardOpen(true)} showSubmitClaim={props.showClaimSubmissionModal} onClose={()=>{         
         postToNativeApp({ "hideFooter": false })
         props.setShowClaimSubmissionModal(false)
       }}/>}
    </div>
    </>
  )
}

export default AccountComponent;