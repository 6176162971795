import React, { useEffect } from 'react'
import {  useParams } from 'react-router';
import Loader from './shared/Loader';

const ClaimDetailsRedirection = () => {

const {param} = useParams();


//  useEffect(() => {
//     window.location.href = `${window.location.origin}/old1/claimDetails/${encodeURIComponent(param)}`;

//  },[])

  return (
    <Loader/>
  )
}

export default ClaimDetailsRedirection